import PropTypes from 'prop-types'
import React from 'react'
import cn from 'classnames'

import OpenHouse from '../OpenHouse'
import { PlaceHolderHouseUrl } from '../../Svg'
import { ThreeDTour, VideoTour } from '../../Svg'

const CardImage = ({
  containsVideoTour,
  containsZillow3dTour,
  featuredImageUrl,
  closestOpenHouseDate,
  closestOpenHouseTime,
  isRecent,
  isActive,
  status
}) => {
  const isOpenHouse = closestOpenHouseDate && closestOpenHouseTime

  return (
    <div className='CardDesktop-imageContainer'>
      <div className='CardDesktop-topFeature'>
        <div className='CardDesktop-innnerWrapper'>
          {isRecent && (
            <div className='CardDesktop-newBadge'>
              <span className='CardDesktop-newBadge--text'>New</span>
            </div>
          )}
        </div>
      </div>
      <div className='CardDesktop-imgOverlay'>
        <div
          className={cn('CardDesktop-imgOverlayInner', { 'CardDesktop-imgOverlayInner--up': isOpenHouse })}
        >
          <div className='CardDesktop-statusInfo'>
            <div
              className={cn('CardDesktop-indicator', { 'CardDesktop-indicator--inactive': !isActive })}
            />
            <p className='CardDesktop-statusLabel'>{status}</p>
          </div>
          <div className='CardDesktop-tours'>
            {containsVideoTour && (
              <VideoTour className='CardDesktop-icon' />
            )}
            {containsZillow3dTour && (
              <ThreeDTour className='CardDesktop-icon' />
            )}
          </div>
        </div>
      </div>

      <picture className='CardDesktop-aspectRatioContent'>
        <source srcSet={featuredImageUrl?.webp} type='image/webp' />
        <img
          src={featuredImageUrl?.jpeg}
          className='CardDesktop-image'
          data-sprinkle='replace-broken-image'
          data-replace-broken-image-url={PlaceHolderHouseUrl}
          alt=''
        />
      </picture>

      {isOpenHouse && (
        <OpenHouse closestOpenHouseDate={closestOpenHouseDate} closestOpenHouseTime={closestOpenHouseTime} />
      )}
    </div>
  )
}

CardImage.propTypes = {
  containsVideoTour: PropTypes.bool,
  containsZillow3dTour: PropTypes.bool,
  featuredImageUrl: PropTypes.shape({
    webp: PropTypes.string,
    jpeg: PropTypes.string
  }),
  closestOpenHouseDate: PropTypes.string,
  closestOpenHouseTime: PropTypes.string,
  isRecent: PropTypes.bool,
  status: PropTypes.string,
  isActive: PropTypes.bool
}

export default CardImage
