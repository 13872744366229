import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Button from '../../../shared_components/Button'
import store from '../SearchBar/stores/rootStoreInstance'

const NoResultsInfo = () => {
  const handleResetFiltersClick = useCallback(() => {
    store.valueStateStore.resetAllInputs()
    store.valueStateStore.immediateSubmit(store.listingTypeStore)
  }, [])

  return (
    <React.Fragment>
      <div className='NoResultsInfo'>
        <p className='NoResultsInfo-information'>No matching results</p>
        <p className='NoResultsInfo-recomendation'>Please try entering different search criteria</p>
        <Button
          subType='black'
          variant='outline'
          size='medium'
          type='customer'
          onClick={handleResetFiltersClick}
        >
          Reset Filters
        </Button>
      </div>
      <div className='NoResultsInfo-line' />
    </React.Fragment>
  )
}

NoResultsInfo.propTypes = {
  collection: PropTypes.array,
  header: PropTypes.string.isRequired,
  useHandDrawing: PropTypes.bool,
  usePropertyPolygons: PropTypes.bool
}

export default NoResultsInfo
